import axios from "axios";
import { guestInfo } from "./Guest";
import { headerConfig, headerConfigWithAuthorize } from "./Header";
import { loadState, saveState, clearAllState } from "state/utils/localStorage";
import { getLang } from "../state/reducers/langSession";
const isBrowser = typeof window !== "undefined";

const getUser = () => {
  let user = loadState("gatsbyUser", true);

  if (!user) return {};

  return user;
};

export const setUser = (user) => {
  saveState("gatsbyUser", JSON.stringify(user));
};

export const setAuthToken = (token) => {
  if (token) axios.defaults.headers.common["Authorization"] = `bearer ${token}`;
  else delete axios.defaults.headers.common["Authorization"];
};

export const setMessages = (responseMessages) => {
  return {
    data: responseMessages !== undefined ? responseMessages.data : "",
    message: responseMessages !== undefined ? responseMessages.message : "",
    status_code:
      responseMessages !== undefined ? responseMessages.status_code : "",
    success: responseMessages !== undefined ? responseMessages.success : "",
  };
};

export const checkUserEmail = async (params) => {
  try {
    let email = params.email;
    let message = "";
    const bodyFormData = new FormData();
    bodyFormData.append("email", email);
    bodyFormData.append("source", "website");

    const requestData = await axios
      .post(
        `${process.env.GATSBY_API_URL}/api/guest/check-login`,
        bodyFormData,
        headerConfig()
      )
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        } else if (error.request) {
          return error.request.data;
        } else {
          console.log("Error", error.message);
          return false;
        }
      });

    if (requestData.success == true) {
      // case if activation page
      if (requestData.status_code == 200111) {
        const {
          data,
          status_code,
          message,
          success,
        } = await checkActivationEmail(requestData.data.token);

        if (status_code == 400713) {
          // invalid token
          return setMessages({
            data: data,
            message: message,
            status_code: status_code,
            success: success,
          });
        } else if (status_code == 400714) {
          // Still waiting for verification
          saveState("token", requestData.data.token);
        } else {
          // other status code
          return setMessages({
            data: data,
            message: message,
            status_code: status_code,
            success: success,
          });
        }
      }
    }

    return setMessages({
      data: requestData.data,
      message: requestData.message,
      status_code: requestData.status_code,
      success: requestData.success,
    });
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const handleLogin = async (params) => {
  try {
    if (!isBrowser) return false;

    let email = params.email;
    let password = params.password;
    const bodyFormData = new FormData();
    bodyFormData.append("email", email);
    bodyFormData.append("password", password);
    bodyFormData.append("source", "website");

    const requestData = await axios
      .post(
        `${process.env.GATSBY_API_URL}/api/guest/login`,
        bodyFormData,
        headerConfig()
      )
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        } else if (error.request) {
          return error.request.data;
        } else {
          console.log("Error", error.message);
          return false;
        }
      });

    if (requestData.success == true) {
      if (requestData.status_code == 200100) {
        // if sudah aktivasi status = 1
        if (requestData.data.status == 1) {
          if (await guestInfo(requestData.data.token)) {
            return setMessages({
              data: {},
              message: "Success fetch data guest",
              status_code: 200100,
              success: true,
            });
          } else {
            return setMessages({
              data: {},
              message: "Unauthorized",
              status_code: 401,
              success: false,
            });
          }
        } else {
          // belom aktivasi status = 0

          await sendEmailVerification(requestData.data.token);
          // send mail veryfication
          const {
            data,
            message,
            status_code,
            success,
          } = await checkVerificationEmail(requestData.data.token);

          return setMessages({
            data: data,
            message: message,
            status_code: status_code,
            success: success,
          });
        }
      } else {
        return setMessages({
          data: {},
          message: requestData.message,
          status_code: requestData.status_code,
          success: false,
        });
      }
    } else {
      return setMessages({
        data: {},
        message: requestData.message,
        status_code: requestData.status_code,
        success: false,
      });
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const checkActivationEmail = async (token) => {
  try {
    if (!token) token = getUserToken();

    const bodyFormData = new FormData();
    bodyFormData.append("token", token);

    const requestData = await axios
      .post(
        `${process.env.GATSBY_API_URL}/api/guest/check-activation`,
        bodyFormData,
        headerConfigWithAuthorize(token)
      )
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        } else if (error.request) {
          return error.request.data;
        } else {
          console.log("Error", error.message);
          return false;
        }
      });

    return setMessages({
      data: requestData.data,
      message: requestData.message,
      status_code: requestData.status_code,
      success: requestData.success,
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateTokenActivation = async (token, key) => {
  try {
    if (!token) token = getUserToken();
    if (!key) key = "";

    const config = {
      headers: {
        "ayana-lang": getLang(),
        "Content-Type": "application/x-www-form-urlencoded",
        "Accept-Language": getLang(),
      },
    };

    const requestData = await axios
      .put(
        `${process.env.GATSBY_API_URL}/api/guest/guest-requests/request-token/${token}`,
        `meta={"verification":1}&key=${key}`,
        config
      )
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        } else if (error.request) {
          return error.request.data;
        } else {
          console.log("Error", error.message);
          return false;
        }
      });

    return setMessages({
      data: requestData.data,
      message: requestData.message,
      status_code: requestData.status_code,
      success: requestData.success,
    });
  } catch (error) {
    console.log(error);
  }
};

export const checkVerificationEmail = async (token) => {
  try {
    if (!token) token = getUserToken();

    const requestData = await axios
      .post(
        `${process.env.GATSBY_API_URL}/api/guest/check-verification`,
        {},
        headerConfigWithAuthorize(token)
      )
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        } else if (error.request) {
          return error.request.data;
        } else {
          console.log("Error", error.message);
          return false;
        }
      });

    if (requestData.status_code == 200108) {
      saveState("token", token);
    }

    return setMessages({
      data: requestData.data,
      message: requestData.message,
      status_code: requestData.status_code,
      success: requestData.success,
    });
  } catch (error) {
    console.log(error);
  }
};

export const sendEmailActivation = async () => {
  try {
    const bodyFormData = new FormData();
    bodyFormData.append("token", getUserToken());
    bodyFormData.append("source", "website");
    const requestData = await axios
      .post(
        `${process.env.GATSBY_API_URL}/api/guest/resend-activation`,
        bodyFormData,
        headerConfigWithAuthorize(getUserToken())
      )
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        } else if (error.request) {
          return error.request.data;
        } else {
          console.log("Error", error.message);
          return false;
        }
      });

    return setMessages({
      data: requestData.data,
      message: requestData.message,
      status_code: requestData.status_code,
      success: false,
    });
  } catch (error) {
    console.log(error);
  }
};

export const sendEmailVerification = async (token) => {
  try {
    if (!token) {
      token = getUserToken();
    }

    const bodyFormData = new FormData();
    bodyFormData.append("source", "website");

    const requestData = await axios
      .post(
        `${process.env.GATSBY_API_URL}/api/guest/resend-email`,
        bodyFormData,
        headerConfigWithAuthorize(token)
      )
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        } else if (error.request) {
          return error.request.data;
        } else {
          console.log("Error", error.message);
          return false;
        }
      });

    return setMessages({
      data: requestData.data,
      message: requestData.message,
      status_code: requestData.status_code,
      success: requestData.success,
    });
  } catch (error) {
    console.log(error);
  }
};

export const setExpiryStorage = () => {
  var hours = 2; // Reset when storage is more than 2hours
  var now = new Date().getTime();
  var setupTime = loadState("setupTime");
  if (setupTime == null) {
    saveState("setupTime", now);
  } else {
    if (now - setupTime > hours * 60 * 60 * 1000) {
      clearAllState();
      saveState("setupTime", now);
    }
  }
};

export const getUserToken = () => {
  const token = loadState("token");
  return token;
};

export const deleteUserToken = () => {
  const token = saveState("token", "");
  return token;
};

export const setAuthTokenState = (token) => {
  saveState("auth_token", token);
  return token;
};

export const getAuthToken = () => {
  const token = loadState("auth_token");
  return token;
};

export const deleteAuthToken = () => {
  const token = saveState("auth_token", "");
  return token;
};

export const isLoggedIn = () => {
  if (!isBrowser) return false;

  const user = getUser();
  const token = getAuthToken();

  if (token === "") return false;

  return !!user.guest?.id;
};

export const getCurrentUser = () => isBrowser && getUser();

export const logout = async (callback) => {
  try {
    if (!isBrowser) return;

    const token = getAuthToken();

    const requestData = await axios
      .get(
        `${process.env.GATSBY_API_URL}/api/guest/logout`,
        headerConfigWithAuthorize(token)
      )
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        } else if (error.request) {
          return error.request.data;
        } else {
          console.log("Error", error.message);
        }
      });

    if (requestData.status_code == 200109) {
      setUser({});
      deleteAuthToken();
      clearAllState();
      callback();
    } else {
      console.log(requestData.message);
      clearAllState();
      callback();
    }
  } catch (e) {
    console.log(e);
  }
};
