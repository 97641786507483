import { getCurrentUser } from "../services/Auth";
import { loadState } from "state/utils/localStorage";
import { langs } from "../data/preferredLanguages";
import { phone_code } from "../data/phonecode";

export const setUserData = () => {
  try {
    // let rewardsProfile = {};
    const userData = getCurrentUser();
    const rewardsProfileMessage = loadState("rewards_profile_error_message");
    const show_profile_error_message = loadState("show_profile_error_message");
    const dateBirthDate = userData?.guest?.birthdate
      ? userData?.guest?.birthdate.split("-")
      : "";

    const objLang = langs.find(
      (lang) => lang.label === userData?.guest?.language?.toLowerCase()
    );

    const objPhoneWithCode = phone_code.find(
      (code) => code.id === userData?.guest?.phoneCountryCode?.toLowerCase()
    );

    return {
      guest_id: userData?.guest?.id ? userData?.guest?.id : 0,
      firstname: userData?.guest?.firstName ? userData?.guest?.firstName : "",
      lastname: userData?.guest?.lastName ? userData?.guest?.lastName : "",
      fullname: userData?.guest?.firstName
        ? `${userData?.guest?.firstName} ${userData?.guest?.lastName}`
        : "",
      email: userData?.guest?.email ? userData?.guest?.email : "",
      language: userData?.guest?.language ? userData?.guest?.language : "",
      nationality: userData?.guest?.nationality
        ? userData.guest.nationality
        : "",
      gender: userData?.guest?.gender ? userData?.guest?.gender : "",
      gender_label: userData?.guest?.gender
        ? userData?.guest?.gender == "f"
          ? "Female"
          : "Male"
        : "",
      birthday: userData?.guest?.birthdate ? userData?.guest?.birthdate : "",
      years: dateBirthDate[0] ? dateBirthDate[0] : "1980",
      month: dateBirthDate[1] ? dateBirthDate[1] : "00",
      day: dateBirthDate[2] ? dateBirthDate[2] : "00",
      phone: userData?.guest?.phoneNumber ? userData?.guest?.phoneNumber : "",
      phone_number: userData?.guest?.phoneNumber
        ? userData?.guest?.phoneNumber
        : "",
      phone_code: userData?.guest?.phoneCountryCode
        ? userData?.guest?.phoneCountryCode
        : "",
      integer_phone_country_code: userData?.guest?.integerPhoneCountryCode
        ? userData?.guest?.integerPhoneCountryCode
        : "",
      status: userData?.rewardsInfo?.status
        ? userData?.rewardsInfo?.status
        : "",
      salutation: userData?.guest?.salutation
        ? userData?.guest?.salutation
        : "",
      address: userData?.guest?.address ? userData?.guest?.address : "",
      country_of_residence: userData?.guest?.countryOfResidence
        ? userData?.guest?.countryOfResidence
        : "",
      subscribe: userData?.guest?.subscribeNewsletter
        ? userData?.guest?.subscribeNewsletter
        : "",
      monthly_notification: userData?.rewardsInfo?.subscribeMonthlyNotification
        ? userData?.rewardsInfo?.subscribeMonthlyNotification
        : "",
      ayana_reward_id: userData?.guest?.ayanaRewardId
        ? userData?.guest?.ayanaRewardId
        : "",
      active: userData?.guest?.active ? userData?.guest?.active : "",
      join_date: userData?.guest?.joinDate ? userData?.guest?.joinDate : "",
      level: userData?.rewardsInfo?.level ? userData?.rewardsInfo?.level : "",
      experienceNeededToNextLevel: userData?.rewardsInfo
        ?.experienceNeededToNextLevel
        ? userData?.rewardsInfo?.experienceNeededToNextLevel
        : "0",
      balance: userData?.rewardsInfo?.points
        ? userData?.rewardsInfo?.points
        : "0",
      nextLevelProgressPct: userData?.rewardsInfo?.nextLevelProgressPercentage
        ? userData?.rewardsInfo?.nextLevelProgressPercentage
        : "0",
      nextlevel: userData?.rewardsInfo?.nextLevel
        ? userData?.rewardsInfo?.nextLevel
        : "0",
      // expiredAt: rewardsProfile.expiredAt ? rewardsProfile.expiredAt : "",
      profileErrorMessage: rewardsProfileMessage ? rewardsProfileMessage : null,
      country_of_residence_label: userData?.guest?.countryOfResidence
        ? userData?.guest?.countryOfResidence
        : "",
      nationality_country: userData?.guest?.nationality
        ? userData.guest.nationality
        : "",
      phone_with_phone_code: !objPhoneWithCode ? "" : objPhoneWithCode.code,

      preferred_language: !objLang ? "English" : objLang.slug,

      show_profile_error_message: show_profile_error_message
        ? show_profile_error_message
        : "0",
    };
  } catch (error) {
    console.log(error);
  }
};
