import { Link, navigate } from "gatsby";
import React, { useState, useEffect } from "react";
import SelectLanguage from "../components/SelectLanguage";
import Img_person from "../images/icons/person.svg";
import { isLoggedIn, logout } from "../services/Auth";
import { setUserData } from "../data/setUserData";
import { Menu, Dropdown, Form, DatePicker, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { SUCCESS } from "state/constants/actions";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import { clearAllState } from "state/utils/localStorage";

const Header = (props) => {
  const {
    loginWithRedirect,
    logout: logoutFromAuth0,
    isAuthenticated,
    isLoading,
  } = useAuth0();
  let userData = setUserData();

  const { member: memberPoint } = useSelector((state) => state.memberPoint);
  const [isMobileView, setMobileView] = useState(false);
  const [hideNav, setHideNav] = useState(false);
  const [show_nav_mobile, setNavMobile] = useState(false);
  const [show_book_now_widget, setBookNowWidget] = useState(false);
  const bookNowUrlABE = {
    en:
      "https://bookings.ayana.com/en/bookings/rooms/?locale=en-US&language=en_US&current_lang=en&current_location=&type=room-reservations&promo=&on_validate_promo_code=&currency=USD",
    ja:
      "https://bookings.ayana.com/ja/bookings/rooms/?locale=ja-JP&language=ja_JP&current_lang=ja&current_location=&type=room-reservations&promo=&on_validate_promo_code=&currency=USD",
    zh:
      "https://bookings.ayana.com/zh/bookings/rooms/?locale=zh-CN&language=zh_CN&current_lang=zh&current_location=&type=room-reservations&promo=&on_validate_promo_code=&currency=CNY",
  };

  const [form] = Form.useForm();
  const [arrival_date, setArrivalDate] = useState(moment());
  const [departure_date, setDepartureDate] = useState(moment().add(1, "days"));
  const [widget_default_value] = useState({
    start_date: "",
    end_date: "",
    hotel: "Bali",
    adults_count: "1",
    childs_count: "0",
    room: "1",
  });

  const [adult_select_dropdown, setAdultSelectDropdown] = useState([1, 2, 3]);
  const [children_select_dropdown, setChildrenSelectDropdown] = useState([
    0,
    1,
    2,
  ]);

  const isAuth0Enabled = process.env.GATSBY_ENABLE_AUTH0 === "true";
  const userHasLoggedIn = isAuth0Enabled ? isAuthenticated : isLoggedIn();

  const handleChangeaValue = (changedValues, allValues) => {
    if (changedValues.room != undefined) {
      form.setFieldsValue({
        adults_count: changedValues.room,
        childs_count: "0",
      });
    }

    let newSelectAdult = [];
    for (var i = allValues.room; i < allValues.room * 3 + 1; i++) {
      newSelectAdult.push(i);
    }
    setAdultSelectDropdown(newSelectAdult);

    let newSelectChildren = [];
    if (changedValues.room != undefined) {
      for (var i = 0; i < allValues.room * 3 - allValues.room + 1; i++) {
        newSelectChildren.push(i);
      }
    } else {
      for (
        var i = 0;
        i < allValues.room * 3 - allValues.adults_count + 1;
        i++
      ) {
        newSelectChildren.push(i);
      }
    }
    setChildrenSelectDropdown(newSelectChildren);
  };

  const { member: dataMemberPoint, uiState } = useSelector(
    (state) => state.dataMemberPoint
  );

  useEffect(() => {
    let currentHideNav = window.innerWidth <= 800;
    setMobileView(currentHideNav);
    setHideNav(currentHideNav);
    setNavMobile(false);
    setUserData();
  }, [dataMemberPoint, memberPoint]);

  useEffect(() => {
    if (uiState === SUCCESS) {
      setTimeout(function () {
        headerPage();
      }, 200);
    }
  }, [dataMemberPoint, setUserData, userData]);

  if (typeof window !== "undefined") {
    window.addEventListener("click", (event) => {
      if (show_book_now_widget == true) {
        setBookNowWidget(!show_book_now_widget);
      }
    });
  }

  function disabledDateArrival(current) {
    return current && current < moment().subtract(1, "days");
  }

  function disabledDateDeparture(current) {
    return current && current < moment(arrival_date).add(1, "days");
  }

  function onChangeArrival(date, dateString) {
    setArrivalDate(date);
    setDepartureDate(moment(date).add(1, "days"));
    form.setFieldsValue({ end_date: moment(date).add(1, "days") });
  }

  function onChangeDeparture(date, dateString) {
    setDepartureDate(date);
  }

  const handleLogout = (event) => {
    event.preventDefault();
    if (isAuth0Enabled) {
      clearAllState();
      props.setLoading(isLoading);
      logoutFromAuth0({
        logoutParams: {
          federated: true,
          returnTo:
            props.langkey === "en"
              ? window.location.origin
              : `${window.location.origin}/${props.langkey}/`,
        },
        clientId: process.env.GATSBY_AUTH0_CLIENT_ID,
      });
    } else {
      props.setLoading(true);
      logout(() => navigate(`${props.home_link}login`));
    }
  };

  const handleSubmit = (fieldsValue) => {
    if (
      fieldsValue["hotel"] === "ARSB" ||
      fieldsValue["hotel"] === "RJB" ||
      fieldsValue["hotel"] === "VILLA" ||
      fieldsValue["hotel"] === "SEGARA"
    ) {
      navigate(
        bookNowUrlABE[props.langkey] +
          "&start_date=" +
          arrival_date.format("YYYY-MM-DD") +
          "&end_date=" +
          departure_date.format("YYYY-MM-DD") +
          "&arrive=" +
          arrival_date.format("YYYY-MM-DD") +
          "&depart=" +
          departure_date.format("YYYY-MM-DD") +
          "&location_code=Bali&hotel_code=" +
          fieldsValue["hotel"] +
          "&hotel_code_id=&rooms=" +
          fieldsValue["room"] +
          "&adults_count=" +
          fieldsValue["adults_count"] +
          "&childs_count=" +
          fieldsValue["childs_count"] +
          (userHasLoggedIn ? "&booking_code=AYANAREWARDS" : "") +
          "&utm_source=BookingWidgetRewards&utm_medium=Form&utm_campaign=ABELanding"
      );
    } else if (fieldsValue["hotel"] === "AKWB") {
      navigate(
        bookNowUrlABE[props.langkey] +
          "&start_date=" +
          arrival_date.format("YYYY-MM-DD") +
          "&end_date=" +
          departure_date.format("YYYY-MM-DD") +
          "&arrive=" +
          arrival_date.format("YYYY-MM-DD") +
          "&depart=" +
          departure_date.format("YYYY-MM-DD") +
          "&location_code=Komodo&hotel_code=" +
          fieldsValue["hotel"] +
          "&hotel_code_id=&rooms=" +
          fieldsValue["room"] +
          "&adults_count=" +
          fieldsValue["adults_count"] +
          "&childs_count=" +
          fieldsValue["childs_count"] +
          (userHasLoggedIn ? "&booking_code=AYANAREWARDS" : "") +
          "&utm_source=BookingWidgetRewards&utm_medium=Form&utm_campaign=ABELanding"
      );
    } else if (fieldsValue["hotel"] === "AMJ") {
      navigate(
        bookNowUrlABE[props.langkey] +
          "&start_date=" +
          arrival_date.format("YYYY-MM-DD") +
          "&end_date=" +
          departure_date.format("YYYY-MM-DD") +
          "&arrive=" +
          arrival_date.format("YYYY-MM-DD") +
          "&depart=" +
          departure_date.format("YYYY-MM-DD") +
          "&location_code=Jakarta&hotel_code=" +
          "&hotel_code_id=&rooms=" +
          fieldsValue["room"] +
          "&adults_count=" +
          fieldsValue["adults_count"] +
          "&childs_count=" +
          fieldsValue["childs_count"] +
          (userHasLoggedIn ? "&booking_code=AYANAREWARDS" : "") +
          "&utm_source=BookingWidgetRewards&utm_medium=Form&utm_campaign=ABELanding"
      );
    } else if (fieldsValue["hotel"] === "DXKR") {
      navigate(
        bookNowUrlABE[props.langkey] +
          "&start_date=" +
          arrival_date.format("YYYY-MM-DD") +
          "&end_date=" +
          departure_date.format("YYYY-MM-DD") +
          "&arrive=" +
          arrival_date.format("YYYY-MM-DD") +
          "&depart=" +
          departure_date.format("YYYY-MM-DD") +
          "&location_code=Karawang&hotel_code=" +
          fieldsValue["hotel"] +
          "&hotel_code_id=&rooms=" +
          fieldsValue["room"] +
          "&adults_count=" +
          fieldsValue["adults_count"] +
          "&childs_count=" +
          fieldsValue["childs_count"] +
          (userHasLoggedIn ? "&booking_code=AYANAREWARDS" : "") +
          "&utm_source=BookingWidgetRewards&utm_medium=Form&utm_campaign=ABELanding"
      );
    } else {
      navigate(
        bookNowUrlABE[props.langkey] +
          "&start_date=" +
          arrival_date.format("YYYY-MM-DD") +
          "&end_date=" +
          departure_date.format("YYYY-MM-DD") +
          "&arrive=" +
          arrival_date.format("YYYY-MM-DD") +
          "&depart=" +
          departure_date.format("YYYY-MM-DD") +
          "&location_code=" +
          fieldsValue["hotel"] +
          "&hotel_code=&hotel_code_id=&rooms=" +
          fieldsValue["room"] +
          "&adults_count=" +
          fieldsValue["adults_count"] +
          "&childs_count=" +
          fieldsValue["childs_count"] +
          (userHasLoggedIn ? "&booking_code=AYANAREWARDS" : "") +
          "&utm_source=BookingWidgetRewards&utm_medium=Form&utm_campaign=ABELanding"
      );
    }
  };

  const uiLocaleForAuth0LogingForm =
    props.langkey === "zh" ? "zh-CN" : props.langkey;

  const profilePage =
    props.langkey === "en" ? "/member" : `/${props.langkey}/member`;

  const menu = (
    <Menu>
      <Menu.Item>
        <a href={profilePage}>{props.content.profile_label}</a>
      </Menu.Item>

      <Menu.Item>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a onClick={handleLogout}>{props.content.logout_label}</a>
      </Menu.Item>
    </Menu>
  );

  const isProfileCompleted = userData?.firstname && userData?.lastname;

  const profileDetail = isProfileCompleted
    ? `${userData?.firstname} | ${userData?.balance.toLocaleString()} ${
        props.content.point_label
      }`
    : ` | 0  ${props.content.point_label}`;

  const headerPage = () => {
    userData = setUserData();
    return (
      <>
        <header className="header">
          <div className="header--top headroom headroom--top headroom--not-bottom">
            <SelectLanguage
              selected={props.lang_selected_name}
              langs={props.langs}
            />

            <div className="header__account">
              <div className="header__account__title">
                <img src={Img_person} alt="" className="marr--sm" />
                {!userHasLoggedIn ? (
                  process.env.GATSBY_ENABLE_AUTH0 === "true" ? (
                    <span
                      onClick={() =>
                        loginWithRedirect({
                          authorizationParams: {
                            redirect_uri: window.location.href,
                            ui_locales: uiLocaleForAuth0LogingForm,
                          },
                        })
                      }>
                      {props.content.sign_in}
                    </span>
                  ) : (
                    <Link to={`${props.home_link}login`}>
                      {props.content.sign_in}
                    </Link>
                  )
                ) : (
                  <Dropdown overlay={menu}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}>
                      {profileDetail}
                      <DownOutlined />
                    </a>
                  </Dropdown>
                )}
              </div>
            </div>
          </div>

          <div className="header--middle">
            <div className="header--middle__content">
              <Link to={props.home_link} className="header__logo">
                <img
                  src="https://resource.ayana.com/live/rewards/logo/logo.png"
                  alt="rewards-logo"
                />
              </Link>

              <label
                className="nav__trigger"
                onClick={(e) => {
                  setHideNav(true);
                  setNavMobile(!show_nav_mobile);
                }}>
                <div></div>
                <div></div>
                <div></div>
              </label>
            </div>

            <nav
              className="navmenu"
              id="mobile__menu"
              style={
                hideNav
                  ? show_nav_mobile
                    ? { display: "block" }
                    : { display: "none" }
                  : { display: "block" }
              }>
              {/* <Link to={props.home_link + "loyalty-update"} key="loyalty-update">
                {props.content.menu_loyalty_update}
              </Link> */}
              <Link to={props.home_link + "promotion"} key="promotion">
                {props.content.menu_promotion}
              </Link>
              <Link
                to={props.home_link + "member-benefits"}
                key="member-benefits">
                {props.content.menu_member_benefit}
              </Link>
              <Link to={props.home_link + "partner"} key="partners">
                {props.content.menu_partner}
              </Link>
              <Link to={props.home_link + "reward"}>
                {props.content.menu_reedem}
              </Link>
              <Link to={props.home_link + "faq"} key="faq">
                {props.content.menu_faq}
              </Link>

              {!userHasLoggedIn ? (
                <>
                  {isAuth0Enabled ? (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      onClick={() =>
                        loginWithRedirect({
                          authorizationParams: {
                            ui_locales: uiLocaleForAuth0LogingForm,
                          },
                          appState: {
                            returnTo: `${window.location.href}?contact_us=true&locale=${props.langkey}`,
                          },
                        })
                      }
                      key="contact_us">
                      {props.content.menu_contact_us}
                    </a>
                  ) : (
                    <Link to={`${props.home_link}login`} key="contact_us">
                      {props.content.menu_contact_us}
                    </Link>
                  )}
                  {isAuth0Enabled ? (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      onClick={() =>
                        loginWithRedirect({
                          authorizationParams: {
                            ui_locales: uiLocaleForAuth0LogingForm,
                            redirect_uri: window.location.href,
                          },
                        })
                      }
                      key="login_mobile"
                      className="mobile--only">
                      {props.content.sign_in}
                    </a>
                  ) : (
                    <Link
                      to={`${props.home_link}login`}
                      key="login_mobile"
                      className="mobile--only">
                      {props.content.sign_in}
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link
                    to={props.home_link + "member/#feedback"}
                    key="contact_us"
                    onClick={(event) => {
                      isMobileView && setHideNav(true);
                    }}>
                    {props.content.menu_contact_us}
                  </Link>
                  <Link
                    to={props.home_link + "member"}
                    key="member"
                    className="mobile--only">
                    {props.content.profile_label}
                  </Link>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a onClick={handleLogout} className="mobile--only">
                    {props.content.logout_label}
                  </a>
                </>
              )}

              <Link
                to={props.home_link + "terms-and-conditions-8"}
                key="terms-and-conditions-8"
                className="mobile--only">
                {props.content.menu_tos}
              </Link>

              <SelectLanguage
                is_mobile={true}
                selected={props.lang_selected_name}
                langs={props.langs}
              />

              <button
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setBookNowWidget(!show_book_now_widget);
                }}
                className="button button--cta header--bottom__trigger">
                {props.content.btn_book_now}
              </button>
            </nav>
          </div>
          <div
            onClick={(event) => {
              event.stopPropagation();
            }}
            className="header--bottom"
            style={
              show_book_now_widget == true
                ? { display: "block" }
                : { display: "none" }
            }>
            <Form
              form={form}
              name="bookwidget"
              size="middle"
              initialValues={widget_default_value}
              onFinish={handleSubmit}
              onValuesChange={handleChangeaValue}
              scrollToFirstError>
              <div className="header--bottom--inner datepicker2__container">
                <div className="flex">
                  <div className="home__book__col">
                    <div className="home__book__title">
                      {props.content.widget_label_arrival}
                    </div>
                    <div className="home__book__input">
                      <Form.Item name="start_date" className="m-0">
                        <DatePicker
                          format="DD/MM/YYYY"
                          className="input input--sm input--subtle datepick datepicker2 datepicker2--from"
                          style={{ padding: "3px 10px" }}
                          suffixIcon=""
                          showToday={false}
                          allowClear={false}
                          disabledDate={disabledDateArrival}
                          onChange={onChangeArrival}
                          placeholder="DD/MM/YYYY"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="home__book__col">
                    <div className="home__book__title">
                      {props.content.widget_label_departure}
                    </div>
                    <div className="home__book__input">
                      <Form.Item name="end_date" className="m-0">
                        <DatePicker
                          format="DD/MM/YYYY"
                          className="input input--sm input--subtle datepick datepicker2 datepicker2--to"
                          style={{ padding: "3px 10px" }}
                          suffixIcon=""
                          showToday={false}
                          allowClear={false}
                          disabledDate={disabledDateDeparture}
                          onChange={onChangeDeparture}
                          placeholder="DD/MM/YYYY"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="datepicker--mobile"></div>
                <div className="home__book__col">
                  <div className="home__book__title">
                    {props.content.widget_label_choose_hotel}
                  </div>
                  <div className="home__book__input">
                    <Form.Item name="hotel" className="m-0">
                      <select id="hotel" className="select input input--sm">
                        <option value="Bali">
                          {props.content.widget_dropdown_hotel_option_choose}
                        </option>
                        <option value="AKWB">AYANA Komodo Waecicu Beach</option>
                        <option value="VILLA">AYANA Villas Bali</option>
                        <option value="ARSB">AYANA Resort Bali</option>
                        <option value="RJB">RIMBA by AYANA Bali</option>
                        <option value="SEGARA">AYANA Segara Bali</option>
                        <option value="AMJ">AYANA Midplaza, JAKARTA</option>
                        <option value="DXKR">Delonix Hotel Karawang</option>
                      </select>
                    </Form.Item>
                  </div>
                </div>
                <div className="flex home__book__col">
                  <div className="col">
                    <div className="home__book__title">
                      {props.content.widget_label_room}
                    </div>
                    <div className="home__book__count">
                      <Form.Item name="room" className="m-0">
                        <select id="room" className="select input input--sm">
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col">
                    <div className="home__book__title">
                      {props.content.widget_label_adult}{" "}
                    </div>
                    <div className="home__book__count">
                      <Form.Item name="adults_count" className="m-0">
                        <select
                          id="adults_count"
                          className="select input input--sm">
                          {adult_select_dropdown.map((value, index) => {
                            return <option value={value}>{value}</option>;
                          })}
                        </select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col">
                    <div className="home__book__title">
                      {props.content.widget_label_child}
                    </div>
                    <div className="home__book__count">
                      <Form.Item name="childs_count" className="m-0">
                        <select
                          id="childs_count"
                          className="select input input--sm">
                          {children_select_dropdown.map((value, index) => {
                            return <option value={value}>{value}</option>;
                          })}
                        </select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="home__book__col">
                  <div className="home__book__title"></div>
                  <div className="home__book__action">
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="button button--sm button--cta button--full">
                        {props.content.btn_book_now}
                      </Button>
                    </Form.Item>
                  </div>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      setBookNowWidget(!show_book_now_widget);
                    }}
                    className="button button--secondary tablet--only button--full button--sm header--bottom__trigger mart--md">
                    {props.content.widget_button_cancel}
                  </a>
                </div>
              </div>
            </Form>
          </div>
        </header>
        {(props.showBookNowButton ?? true) && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setBookNowWidget(!show_book_now_widget);
            }}
            className="button button--cta header--bottom__trigger tablet--only button--fixed">
            {props.content.btn_book_now}
          </a>
        )}
      </>
    );
  };

  return <>{headerPage()}</>;
};

export default Header;
